.ms-headline {
    text-align: left;
}

.ms-headline span {
    font-size: var(--headline-h1-mobile-font-size);
    font-weight: 300;
    letter-spacing: var(--headline-h1-mobile-letter-spacing-primary);
    line-height: var(--headline-h1-mobile-line-height);
}

.ms-headline .ms-headline--bold {
    font-size: var(--headline-h1-mobile-font-size);
    font-weight: 700;
    letter-spacing: var(--headline-h1-mobile-letter-spacing-secondary);
}

@media(min-width: 992px) {
    .ms-headline span {
        font-size: var(--headline-h1-desktop-font-size-secondary);
        letter-spacing: var(--headline-h1-desktop-letter-spacing-primary);
        line-height: var(--headline-h1-desktop-line-height);
    }

    .ms-headline .ms-headline--bold {
        font-size: var(--headline-h1-desktop-font-size-secondary);
        letter-spacing: var(--headline-h1-desktop-letter-spacing-primary);
        line-height: var(--headline-h1-desktop-line-height);
    }
}