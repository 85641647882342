.ms-form {
    padding: 0 0.75rem;
}

/* Ant design ovverides */

.ms-form .ant-form-item-label {
    text-align: left;
}

.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: "" !important;
}

.ms-form .ant-form-item label {
    color: var(--ms-light-grey-colour);
    padding-left: 0.5rem;
}

.ms-form .ant-form-item label[for="message"] {
    color: #000;
}

.ms-form button {
    height: 3rem;
    width: 10rem;
    border-radius: 29px;
}

.ms-form .ant-collapse-header,
.ms-form .ant-collapse,
.ms-form .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ms-form .ant-collapse-header {
    padding-left: 8px !important;
}

.ms-form .ant-collapse-header-text {
    font-size: 1.125rem;
}

.ms-form__sent {
    position: relative;
}

.ms-form__sent .ms-headline,
.ms-form__sent .ms-form__text,
.ms-form__sent #ms-contact-form {
    opacity: 0.1;
    transition: opacity 0.4s ease-in-out;
    pointer-events: none;
}

.ms-form__sent .ms-form__success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ms-form .ms-form__privacy {
    color: gray;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
}

@media(min-width: 992px) {
    .ms-form button {
        height: 3rem;
        width: 12rem;
        border-radius: 29px;
        font-size: var(--button-font-size);
    }
}