.ms-mobile-menu * {
    background: var(--ms-primary-colour);
}

.ms-mobile-menu {
    background: var(--ms-primary-colour);
    height: 100%;
    position: fixed;
    width: 100%;
    top: -100%;
    left: 0;
    right: 0;
    transition: transform 0.4s;
    will-change: transform;
    padding: 0 0.625rem;
}

.ms-mobile-menu--active {
    bottom: 0;
    padding-top: 5rem;
    transform: translateY(100%);
    overflow-y: scroll;
}

.ms-mobile-menu a {
    display: inline-block;
    width: 100%;
}

.ms-mobile-menu__arrow {
    cursor: pointer;
    transform: rotate(180deg);
    margin-bottom: 1px;
    will-change: transform;
    transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
}

.ms-mobile-menu__arrow--active {
    transform: rotate(0deg);
}

.ms-mobile-menu__sub-menu {
    max-height: 0;
    margin-top: -1rem;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
}

.ms-mobile-menu__sub-menu li {
    line-height: normal;
}

.ms-mobile-menu-body--active {
    background-color: var(--ms-primary-colour);
    overflow: hidden;
}

.ms-mobile-menu-body--active .ms-carousel {
    opacity: 0;
    transition: opacity 0.4s;
}

/* Hamburger react overrides */

.hamburger-react {
    right: -12px;
}

/* Hide Scrollbar */

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media(min-width: 768px) {
    .ms-mobile-menu {
        padding: 0 35px;
        padding-top: 5rem;
    }
}

@media(min-width: 992px) {
    .ms-mobile-menu {
        display: none;
    }
}