.ms-welcome {
    text-align: center;
    padding: 0 0.75rem;
}

.ms-welcome__discount {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    background-color: #000;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: 1.2em;
    line-height: 4rem;
    transform: rotate(15deg);
    font-weight: 700;
  }

@media(min-width: 992px) {
    .ms-welcome .ms-headline {
        padding-top: 3rem;
    }
}