.ms-testimonial-carousel__left svg,
.ms-testimonial-carousel__right svg {
    fill: #fff;
}

.ms-testimonial-carousel__left path,
.ms-testimonial-carousel__right path {
    fill: #000;
}

@media(min-width: 992px) {
    .ms-testimonial-carousel__left svg,
    .ms-testimonial-carousel__right svg {
        fill: #000;
    }

    .ms-testimonial-carousel__left path,
    .ms-testimonial-carousel__right path {
        fill: #fff;
    }
}