.ms-menu__item {
    color: var(--ms-white-colour);
    font-size: var(--menu-mobile-font-size);
    font-style: normal;
}

.ms-menu__item__sub-item {
    color: var(--ms-white-colour);
    font-size: 1.2rem;
    line-height: 2.5rem;
    font-weight: 300;
    letter-spacing: -0.05rem;
}

/* Start: Mobile */

.ms-menu__item-primary--mobile {
    font-weight: 700;
    letter-spacing: var(--menu-mobile-letter-spacing);
}

.ms-menu__item-secondary--mobile {
    font-weight: 300;
    letter-spacing: var(--menu-mobile-letter-spacing-secondary);
}

/* End: Mobile */

/* Start: Desktop */

@media (min-width: 992px) {
    .ms-menu__item {
        color: #000;
        font-size: var(--menu-desktop-font-size);
        font-weight: 600;
        font-style: normal;
        letter-spacing: var(--menu-desktop-letter-spacing);
    }
}

/* End: Desktop */