.ms-faq {
    margin-top: -2rem;
}

.ms-faq .ant-collapse-content .ant-collapse-content-box ul {
    padding-left: 1.5rem !important;
}

.ms-faq .ant-collapse-header-text {
    font-weight: 700;
}

.ms-faq .ant-collapse-header {
    border-bottom: 1px solid black;
    border-radius: 0 !important;
}

.ms-faq .ms-faq__answer {
    font-size: var(--copy-text-mobile-font-size);
    font-weight: 300;
}

.ms-faq .ant-collapse-header-text {
    font-weight: 600;
}

.ms-faq .ant-collapse-content-box {
    padding: 18px !important;
}

@media(min-width: 992px) {
    .ms-faq .ms-content {
        padding: 0;
    }

    .ms-faq .ant-collapse-header {
        padding-top: 1.5rem;
        padding-bottom: 1.8rem;
    }

    .ms-faq .ms-faq__answer {
        font-size: var(--copy-text-desktop-font-size);
    }
}