.ms-form-field {
    border: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
    box-shadow: none;
    color: black;
    padding-bottom: 1rem;
    outline: none;
    width: 100%;
}

.ms-form-textarea {
    border-color: black;
    color: black;
}

.ms-form-checkbox {
    color: var(--ms-light-grey-colour);
}