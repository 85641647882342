/* Style configurations for melinastylist.com */
:root {
    /* COLOUR CONFIGURATIONS */
    --ms-primary-colour: #000000;
    --ms-secondary-colour: #D3F11C;
    --ms-white-colour: #FFFFFF;
    --ms-light-grey-colour: #A3A1A1;
    
    /* FONT CONFIGURATIONS */

    /* Menu */
    --menu-mobile-font-size: 2rem;
    --menu-mobile-letter-spacing: -0.0825rem;
    --menu-mobile-letter-spacing-secondary: -0.15rem;
    /* ----- */
    --menu-desktop-font-size: 1.125rem;
    --menu-desktop-letter-spacing: -0.06188rem;

    /* Headline */
    --headline-h1-mobile-font-size: 1.5rem;
    --headline-h1-mobile-letter-spacing-primary:  -0.0825rem;
    --headline-h1-mobile-letter-spacing-secondary: -0.15rem;
    --headline-h1-mobile-line-height: 1.75rem;
    /* ----- */
    --headline-h1-desktop-font-size-primary: 4.6875rem;
    --headline-h1-desktop-font-size-secondary: 3.4375rem;
    --headline-h1-desktop-letter-spacing-primary:  -0.25781rem;
    --headline-h1-desktop-letter-spacing-secondary: -0.34375rem;
    --headline-h1-desktop-line-height: 3.625rem;

    /* Copy Text */
    --copy-text-mobile-font-size: 1rem;
    /* ----- */
    --copy-text-desktop-font-size: 1.25rem;

    /* Button */
    --button-font-size: 1rem;
    --button-letter-spacing-primary: -0.02813rem;
    --button-letter-spacing-secondary: -0.09375rem;
    --button-font-size-large: 1.5625rem;

    /* Link */
    --link-mobile-font-size: 0.75rem;
    /* ----- */
    --link-desktop-font-size: 1.25rem;

    /* Placeholder (Form) */
    --placeholder-mobile-font-size: 0.75rem;
    --checkbox-mobile-font-size: 0.75rem;
    /* ----- */
    --placeholder-desktop-font-size: 1.5625rem;
    --checkbox-desktop-font-size: 1.5625rem;

    /* Services Carousel */
    --services-mobile-carousel-text-font-size: 1.5rem;
    /* ----- */
    --services-desktop-carousel-text-font-size: 4.6875rem;

    /* Testimonial Carousel */
    --testimonial-mobile-font-color: var(--ms-white-colour);
    --testimonial-mobile-background-color: var(--ms-primary-colour);
    --testimonial-mobile-headline-font-size: 1rem;
    --testimonial-mobile-headline-letter-spacing: -0.0625rem;
    --testimonial-mobile-review-font-size: 1.25rem;
    --testimonial-mobile-review-letter-spacing: -0.05rem;
    --testimonial-mobile-review-line-height: 1.5rem;
    --testimonial-mobile-name-font-size: 0.9375rem;
    --testimonial-mobile-name-letter-spacing: -0.09375rem;
    /* ----- */
    --testimonial-desktop-font-color: var(--ms-primary-colour);
    --testimonial-desktop-background-color: var(--ms-white-colour);
    --testimonial-desktop-headline-font-size: 1.5625rem;
    --testimonial-desktop-headline-letter-spacing: -0.15625rem;
    --testimonial-desktop-review-font-size: 2.8125rem;
    --testimonial-desktop-review-letter-spacing: -0.2rem;
    --testimonial-desktop-review-line-height: 4rem;
    --testimonial-desktop-name-font-size: 2.5rem;
    --testimonial-desktop-name-letter-spacing: -0.25rem;

    /* Footer */
    --footer-mobile-font-color: var(--ms-white-colour);
    --footer-mobile-font-size: 0.75rem;
    /* ----- */
    --footer-desktop-font-color: var(--ms-white-colour);
    --footer-desktop-font-size: 1.25rem;
}

/* Reset */

/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

header {
    background: transparent !important;
}

body {
    line-height: 1;
    transition: background-color 0.4s;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}

/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000;
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;  
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, select {
    vertical-align: middle;
}

/* Ant design overrides - Start */

.ant-dropdown-menu-title-content {
    color: white;
}

.ant-notification-notice-message {
    font-weight: 700;
    letter-spacing: -0.1rem;
}

.ant-float-btn-body {
    background-color: #fff !important;
}

.ant-notification-notice {
    background-color: #fff !important;
}

/* Ant design overrides - End */

/* Recaptcha override */
.grecaptcha-badge { visibility: hidden !important; }


/* Swiper overrides */

.ms-mobile-menu-body--active swiper-container {
    opacity: 0;
    transition: ease-in-out 0.4s;
}

.ms-component {
    width: 100%; 
    max-width: 80rem; 
    margin: 0 auto;
}

.ms-content {
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 10px;
}

.ms-inner-content {
    padding-bottom: 1rem;
    max-width: 80rem;
    margin: 0 auto;
}

.ms-bottom--spacer {
    margin-bottom: 2rem !important;
}

@media(min-width: 768px) {
    .ms-content {
        padding: 0 25px;
    }
}

@media(min-width: 992px) {
    .ms-content {
        padding: 0 50px;
    }

    .ms-bottom--spacer {
        margin-bottom: 3rem !important;
    }
}