.ms-quote {
    color: var(--testimonial-mobile-font-color);
    font-size: var(--testimonial-mobile-review-font-size);
    font-style: italic;
    font-weight: 300;
    letter-spacing: var(--testimonial-mobile-review-letter-spacing);
    line-height: var(--testimonial-mobile-review-line-height);
    text-align: center;
    padding: 0 1rem;
    max-width: 19.5rem;
    margin: 0 auto;
    vertical-align: middle;
}

.ms-quote q {
    display: block;
    /* height: 2.5rem; */
}

.ms-quote__name {
    display: block;
    text-align: center;
    font-size: var(--testimonial-mobile-name-font-size);
    font-style: normal;
    font-weight: 700;
    letter-spacing: var(--testimonial-mobile-name-letter-spacing);
    line-height: 3rem;
}

@media(min-width: 992px) {
    .ms-quote {
        color: var(--testimonial-desktop-font-color);
        font-size: var(--testimonial-desktop-review-font-size);
        letter-spacing: var(--testimonial-desktop-review-letter-spacing);
        line-height: var(--testimonial-desktop-review-line-height);
        max-width: 100%;
        max-width: 55rem;
        max-lines: 4;
    }

    .ms-quote__name {
        color: #000 !important;
        line-height: 4rem;
        font-size: var(--testimonial-desktop-name-font-size);
        letter-spacing: var(--testimonial-desktop-name-letter-spacing);
    }
}

/* 

    --testimonial-mobile-font-color: var(--ms-white-colour); [x]
    --testimonial-mobile-background-color: var(--ms-primary-colour); 
    --testimonial-mobile-headline-font-size: 0.625rem; 
    --testimonial-mobile-headline-letter-spacing: -0.0625rem;
    --testimonial-mobile-review-font-size: 1.25rem; [x]
    --testimonial-mobile-review-letter-spacing: -0.125rem; [x]
    --testimonial-mobile-review-line-height: 120.182%;
    --testimonial-mobile-name-font-size: 0.9375rem; [x]
    --testimonial-mobile-name-letter-spacing: -0.09375rem; [x]

    --testimonial-desktop-font-color: var(--ms-primary-colour);
    --testimonial-desktop-background-color: var(--ms-white-colour);
    --testimonial-desktop-headline-font-size: 1.5625rem;
    --testimonial-desktop-headline-letter-spacing: -0.15625rem;
    --testimonial-desktop-review-font-size: 4.6875rem;
    --testimonial-desktop-review-letter-spacing: -0.46875rem; 
    --testimonial-desktop-review-line-height: 120.182%; [x]
    --testimonial-desktop-name-font-size: 2.5rem; [x]
    --testimonial-desktop-name-letter-spacing: -0.25rem; [x]

*/