@media (max-width: 479px) {
    .ms-services__slide__title span {
        font-size: 2.25rem;
    } 
}

.ms-services-carousel {
    max-width: 80rem !important;
}

.ms-services__slide .ms-services__slide__container .ms-services__slide__overlay {
    opacity: 0.6;
}

.ms-services__slide:hover .ms-services__slide__container .ms-services__slide__overlay {
    opacity: 0.2 !important;
    transition: opacity 0.4 ease-in-out !important;
}