.ms-logo {
    fill: black;
    height: 2.5rem;
    transition: fill 0.4s;
    will-change: fill;
}

.ms-logo img {
    height: 100%;
}

.ms-logo--white {
    fill: white;
}

/* Animation */

@keyframes rotateIn {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(10%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.ms-logo__container {
  perspective: 1200px; 
}

.ms-logo__word {
  position: absolute;
  transform: rotateX(90deg);
  will-change: transform;
}

.ms-logo__word--hidden {
  transition: transform .32s cubic-bezier(.55,.055,.675,.19);
}

.ms-logo__word--active {
  transform: rotateX(0deg);
  transition: transform .38s cubic-bezier(.175,.885,.32,1.275);
}
  
.ms-logo__word {
    display: none;
}

.ms-logo__word.active {
    animation: rotateIn 0.5s ease-in-out;
    display: inline;
}

@media(min-width: 992px) {
    .ms-logo {
        height: 3.5rem;
    }  
}