.ms-newsletter {
    padding: 0 0.75rem;
}

.ms-newsletter .ms-form-field {
    text-align: center;
}

.ms-newsletter .ms-arrow-button {
    position: absolute;
    left: -25px;
}

.ms-newsletter__sent {
    position: relative;
    background-color: #fff;
    color: #000
}

.ms-newsletter__sent .ms-newsletter__title,
.ms-newsletter__sent .ms-newsletter__text,
.ms-newsletter__sent .ms-newsletter__form {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

.ms-newsletter__sent .ms-newsletter__success {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 10px;
    font-weight: 700;
    line-height: 2rem;
}

.ms-newsletter .ms-newsletter__privacy {
    color: gray;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
}