.ms-service-list {
    font-weight: 700;
    text-align: left;
    list-style-position: outside;
    padding-left: 1rem;
}

.ms-service-list li span {
    font-size: 1rem;
}

@media(min-width: 992px) {
    .ms-service-list li span {
        font-size: 1.2rem;
    }
}