.ms-video-wrapper {
    position: relative;
    height: 100vh;
    width: 100vw;
}

.ms-video-wrapper__overlay {
    position: absolute;
    z-index: 200;
    background: transparent;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
    width: 70%;
}

.ms-video-wrapper__overlay .ms-headline--bold {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 400;
}

.ms-video-background {
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    /* rgba(211, 241, 28, 0.1); */
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
    z-index: 100;
}

.ms-video-wrapper__overlay--visible {
    opacity: 1;
}

@media(min-width: 992px) {
    .ms-video-wrapper__overlay {
        width: 50%;
    }

    .ms-video-wrapper__overlay .ms-headline--bold {
        font-size: 4rem;
    }
}