/* Navigation */

.ms-nav {
  height: 4rem;
  padding: 0 10px;
  z-index: 300 !important;
  position: fixed;
  transition: background 200ms ease-in-out;
}

.ms-nav--scrolled {
  background: rgba(255, 255, 255, 0.9) !important;
}

.ms-nav nav {
  width: 100%;
}

.ms-language-switcher {
  transition: all 0.5s;
}

.ms-nav ul {
  border: 0;
}

.ms-language-switcher__button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ms-blog-disabled .ant-menu-item:nth-child(3) {
  display: none;
}

@media (min-width: 768px) {
  .ms-nav {
    padding: 0 35px;
  }

  .ms-nav .ant-menu-item {
    color: var(--ms-primary-colour);
  }
}

@media (min-width: 992px) {
  .ms-nav {
    height: 5rem;
    padding: 0 50px;
  }

  .ms-language-switcher {
    vertical-align: middle;
  }

  .ms-nav div,
  .ms-nav ul {
    background: transparent;
  }

  /* Ant design overrides */
  .ms-nav .ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
    top: 30px;
    /* border-bottom-color: black; */
  }

  .ant-dropdown {
    /* top: 100px !important; */
  }

}
