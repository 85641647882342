.ms-button {
    font-size: var(--button-font-size);
    font-weight: 300;
    letter-spacing: var(--button-letter-spacing-secondary);
    border-radius: 1rem;
    padding: 0 !important;
    line-height: 30px;
    padding-inline-start: 2.5rem !important;
    padding-inline-end: 2.5rem !important;
    cursor: pointer;
}

.ms-button-text--bold {
    font-weight: 600;
    letter-spacing: var(--button-letter-spacing-primary);
}

.ms-button:hover {
    color: #000 !important;
}

@media(min-width: 992px) {
    .ms-button--large {
        font-size: var(--button-font-size-large);
    }
}

.ms-button--large {
    line-height: 38px;
}

/* Float Button */

.ms-float-button {
    background-color: var(--ms-secondary-colour);
    z-index: 300;
}

.ant-float-btn-body:hover {
    background-color: var(--ms-secondary-colour) !important;
}