.ms-faq-header {
    height: 4rem;
    position: relative;
    padding:  0 10px;
}

.ms-faq-header .ant-row {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.ms-faq-header,
.ms-faq-header * {
    background-color: black;
    color: white;
}

.ms-faq-header .ms-faq-first,
.ms-faq-header .ms-faq-first ~ span {
    font-size: 2rem;
}

.ms-faq-header span:first-child {
    font-weight: 700;
}

.ms-faq-header span:nth-child(2) {
    font-weight: 300;
}

.ms-faq-header__service__title {
    line-height: 1.8rem;
    font-size: 1.25rem;
}

@media(min-width: 992px) {
    .ms-faq-header {
        padding:  0 20px;
    }

    .ms-faq-header .ms-content {
        padding: 0;
    }
}

@media(min-width: 1440px) {
    .ant-collapse-header {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .ant-collapse-content-box {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}