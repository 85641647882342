.ms-testimonial-carousel {
  background-color: var(--testimonial-mobile-background-color);
  padding: 2rem 0;
  z-index: 50;
}

.ms-testimonial-carousel__title {
  color: white;
  font-size: var(--testimonial-mobile-headline-font-size);
  letter-spacing: var(--testimonial-mobile-headline-letter-spacing);
  height: 1.8rem;
  text-align: center;
}

.ms-testimonials-controls {
  cursor: pointer;
  position: absolute;
  padding: 0 5px;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 30rem;
  transform: translateY(-50%);
  transform: translateX(-50%);
  z-index: 500;
}

.ms-testimonial-carousel__left,
.ms-testimonial-carousel__right {
  cursor: pointer;
}

.ms-carousel swiper-slide {
    align-self: center; /* add this will all relevant prefixes */
    display: flex;
}

@media (min-width: 992px) {
  .ms-testimonial-carousel {
    z-index: 2000;
    background-color: var(--testimonial-desktop-background-color);
  }

  .ms-testimonials-controls {
    max-width: 70rem;
  }

  .ms-testimonial-carousel__title {
    font-size: var(--testimonial-desktop-headline-font-size);
    letter-spacing: var(--testimonial-desktop-headline-letter-spacing);
    color: black;
  }

  .ms-testimonial-carousel__left,
  .ms-testimonial-carousel__right {
    padding: 0 50px;
  }
}
