.ms-arrow-button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.ms-arrow-button path {
    fill: #fff;
}